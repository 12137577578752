export const isGroupTestAvailable = ({
  group,
}: {
  group: {
    testDate: Date | undefined | string;
  };
}): boolean => {
  if (!group.testDate) {
    return false;
  }

  const groupTestDate = new Date(group.testDate);
  return (
    new Date() >= new Date(groupTestDate.getTime() - 5 * 24 * 60 * 60 * 1000)
  );
};
