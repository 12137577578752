import { useParams } from "@tanstack/react-router";
import { ResponseForPath, useApiQuery } from "../../../../../lib/api";

export const useTestScanPages = () => {
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  const testScanPagesQuery = useApiQuery("/groups/:id/test-scan-pages", {
    id: params.id,
  });

  return testScanPagesQuery;
};

export const useGroupQuery = () => {
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  return useApiQuery("/groups/:id", {
    id: params.id,
  });
};

export type Scan = ResponseForPath<
  "/groups/:id/test-scan-pages",
  "get"
>["data"][number];
