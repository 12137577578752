import {
  Badge,
  Button,
  Checkbox,
  Column,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import Icon from "@gradience/ui/dist/components/icon";
import { useApiPut, useApiQuery } from "../../../../../lib/api";
import { useParams } from "@tanstack/react-router";
import { useRef, useState } from "react";
import { Student } from "@gradience/api-types";
import { Scan, useGroupQuery, useTestScanPages } from "./utils";
import { ScanCard } from "./scan-card";
import { DuplicateScans } from "./duplicate-scans";
import { EmptyScanCard } from "./empty-scan-card";
import UploadTestsModal from "../../upload-tests-modal";

export function StudentRow({ student }: { student: Student }) {
  const [_expanded, setExpanded] = useState<boolean>();
  const expanded = _expanded ?? false;

  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  const updateStudent = useApiPut("/students/:id");
  const students = useApiQuery("/students", {}, undefined, {
    groupId: params.id,
  });

  const didNotTakeTest = student.skipGrading;
  const setDidNotTakeTest = async (value: boolean) => {
    await updateStudent.mutateAsync({
      body: {
        ...student,
        skipGrading: value,
      },
      params: {
        id: student.id,
      },
    });
    students.refetch();
    groupQuery.refetch();
  };

  const designTokens = useDesignTokens();
  const groupQuery = useApiQuery("/groups/:id", {
    id: params.id,
  });

  const testScanPagesQuery = useTestScanPages();
  const numberOfPagesUploaded = testScanPagesQuery.data?.data
    .filter((p) => p.studentId === student.id && p.discardedAt === undefined)
    .filter(
      // unique by page number
      (scan, index, self) =>
        self.findIndex((s) => s.pageNumber === scan.pageNumber) === index
    ).length;

  const hasDuplicates =
    numberOfPagesUploaded !==
    testScanPagesQuery.data?.data.filter(
      (p) => p.studentId === student.id && p.discardedAt === undefined
    ).length;

  return (
    <Column>
      <Row
        style={{
          borderTop: `1px solid ${designTokens.colors.border.subdued}`,
          cursor: "pointer",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Column style={{ padding: 24, flex: 1 }}>
          <Text>
            {student.firstName} {student.lastName}
          </Text>
        </Column>
        <Column style={{ padding: 24, flex: 1 }}>
          <Row>
            <Badge
              type={
                hasDuplicates
                  ? "warning"
                  : numberOfPagesUploaded === 0
                    ? didNotTakeTest
                      ? "success"
                      : "warning"
                    : numberOfPagesUploaded ===
                        groupQuery.data?.numberOfPagesInTest
                      ? "success"
                      : "warning"
              }
              icon={
                hasDuplicates
                  ? "information-circle-contained"
                  : numberOfPagesUploaded === 0
                    ? didNotTakeTest
                      ? "check-01"
                      : "close"
                    : numberOfPagesUploaded ===
                        groupQuery.data?.numberOfPagesInTest
                      ? "check-01"
                      : "information-circle-contained"
              }
            >
              {hasDuplicates
                ? "Potential Duplicate Pages Detected"
                : didNotTakeTest
                  ? "Did Not Take Test"
                  : `${numberOfPagesUploaded}/${groupQuery.data?.numberOfPagesInTest} Pages Uploaded`}
            </Badge>
          </Row>
        </Column>
        <Column
          style={{
            width: 60,
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon name={expanded ? "chevron-up" : "chevron-down"} />
        </Column>
      </Row>
      {expanded &&
        (numberOfPagesUploaded === 0 ? (
          <NoScans
            student={student}
            setDidNotTakeTest={setDidNotTakeTest}
            didNotTakeTest={didNotTakeTest}
            loading={
              testScanPagesQuery.isPending ||
              updateStudent.isPending ||
              students.isPending
            }
          />
        ) : (
          <Scans student={student} />
        ))}
    </Column>
  );
}

const NoScans = ({
  student,
  setDidNotTakeTest,
  didNotTakeTest,
  loading,
}: {
  student: Student;
  didNotTakeTest: boolean;
  setDidNotTakeTest: (value: boolean) => unknown;
  loading: boolean;
}) => {
  const designTokens = useDesignTokens();
  const testScanPagesQuery = useTestScanPages();
  const groupQuery = useGroupQuery();
  const [uploading, setUploading] = useState(false);
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  return (
    <div
      style={{
        margin: 16,
        minWidth: 0,
        alignSelf: "flex-start",
        marginTop: 0,
        backgroundColor: designTokens.colors.gray[4],
        borderRadius: 16,
        padding: 24,
        gap: 16,
        overflowX: "auto",
        boxSizing: "border-box",
        width: "calc(100% - 32px)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Text textStyle="16px - Semibold">No Pages were uploaded</Text>
      <Column
        gap={16}
        style={{
          alignItems: "flex-start",
        }}
      >
        <label
          style={{
            padding: "10px 0",
            gap: 8,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            cursor: "pointer",
          }}
        >
          <Checkbox
            value={student.skipGrading}
            onChange={setDidNotTakeTest}
            disabled={loading}
          />
          <Text textStyle="14px - Medium">
            This student did not take the test
          </Text>
        </label>
      </Column>
      <UploadTestsModal
        onComplete={() => {
          testScanPagesQuery.refetch();
          groupQuery.refetch();
          setUploading(false);
        }}
        onClose={() => setUploading(false)}
        isOpen={uploading}
        groupId={params.id}
      />
      {didNotTakeTest ? null : (
        <Button
          leadingIcon="upload-03"
          text="Upload Pages"
          onPress={() => {
            setUploading(true);
          }}
        />
      )}
    </div>
  );
};

const Scans = ({ student }: { student: Student }) => {
  const designTokens = useDesignTokens();
  const testScanPagesQuery = useTestScanPages();
  const pagesRowRef = useRef<HTMLDivElement>(null);
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });
  const groupQuery = useApiQuery("/groups/:id", {
    id: params.id,
  });

  return (
    <div
      ref={pagesRowRef}
      style={{
        margin: 16,
        minWidth: 0,
        alignSelf: "flex-start",
        marginTop: 0,
        backgroundColor: designTokens.colors.gray[8],
        borderRadius: 16,
        padding: 24,
        gap: 16,
        overflowX: "auto",
        boxSizing: "border-box",
        width: "calc(100% - 32px)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row gap={8} style={{ alignItems: "flex-start" }}>
        {(() => {
          // Create a mapping from page number to scans for the current student
          const pages =
            testScanPagesQuery.data?.data
              .filter((scan) => scan.studentId === student.id)
              .filter((scan) => scan.discardedAt === undefined)
              .sort((a, b) => a.pageNumber - b.pageNumber)
              .reduce(
                (acc, scan) => {
                  const { pageNumber } = scan;
                  if (!acc[pageNumber]) {
                    acc[pageNumber] = [];
                  }
                  acc[pageNumber].push(scan);
                  return acc;
                },
                {} as Record<number, Scan[]>
              ) ?? {};

          const totalPages = groupQuery.data?.numberOfPagesInTest ?? 0;
          const items = [];

          // Loop through all page numbers from 1 to totalPages
          for (let page = 1; page <= totalPages; page++) {
            const scans = pages[page];

            if (scans) {
              // If there's only one scan, render a ScanCard
              if (scans.length === 1) {
                items.push(
                  scans.map((scan) => (
                    <ScanCard
                      scan={scan}
                      key={scan.id}
                      loading={testScanPagesQuery.isPending}
                    >
                      <Row style={{ padding: "8px 16px" }}>
                        <Text textStyle="14px - Medium">
                          Page {scan.pageNumber}
                        </Text>
                      </Row>
                    </ScanCard>
                  ))
                );
              } else {
                // If there are duplicate scans, render the DuplicateScans component
                items.push(
                  <DuplicateScans key={`duplicate-${page}`} scans={scans} />
                );
              }
            } else {
              // Render a placeholder for pages that have no scans
              items.push(<EmptyScanCard page={page} />);
            }
          }

          return items;
        })()}
        <span style={{ width: 16, flexShrink: 0, height: 1 }} />
      </Row>
      <Row
        style={{
          position: "sticky",
          left: 0,
        }}
      >
        <button
          style={{
            appearance: "none",
            border: "none",
            backgroundColor: designTokens.colors.surface.Background,
            padding: 4,
            borderRadius: 8,
            lineHeight: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            if (pagesRowRef.current) {
              pagesRowRef.current.scrollTo({
                left: pagesRowRef.current.scrollLeft - 226,
                behavior: "smooth",
              });
            }
          }}
        >
          <Icon name="arrow-left" />
        </button>
        <span style={{ flex: 1 }} />
        <button
          style={{
            appearance: "none",
            border: "none",
            backgroundColor: designTokens.colors.surface.Background,
            padding: 4,
            borderRadius: 8,
            lineHeight: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            if (pagesRowRef.current) {
              pagesRowRef.current.scrollTo({
                left: pagesRowRef.current.scrollLeft + 226,
                behavior: "smooth",
              });
            }
          }}
        >
          <Icon name="arrow-right" />
        </button>
      </Row>
    </div>
  );
};
