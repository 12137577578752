var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useDesignTokens } from "../lib/design-tokens";
var ProgressBar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", "%;\n  height: 8px;\n  background-color: ", ";\n  transition: width 0.5s;\n"], ["\n  width: ", "%;\n  height: 8px;\n  background-color: ", ";\n  transition: width 0.5s;\n"])), function (props) { return props.progress * 100; }, function (_a) {
    var theme = _a.theme;
    return theme.colors.icon.brand;
});
/**
 * A progress bar. The progress is a number between 0 and 1.
 */
var Progress = function (_a) {
    var progress = _a.progress;
    var designTokens = useDesignTokens();
    return (_jsx("div", { style: {
            borderRadius: 4,
            overflow: "hidden",
            height: 8,
            backgroundColor: designTokens.colors.gray[12],
        }, children: _jsx(ProgressBar, { progress: progress }) }));
};
export default Progress;
var templateObject_1;
