import {
  Badge,
  Button,
  Column,
  Loader,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { Scan, useGroupQuery, useTestScanPages } from "./utils";
import { ScanImage } from "./components";
import { useState } from "react";
import { useApiPost } from "../../../../../lib/api";
import { useParams } from "@tanstack/react-router";
import { ScanWithFields } from "./scan-with-fields";

export function DuplicateScans({
  scans,
  loading: _loading,
}: {
  scans: Scan[];
  loading?: boolean;
}) {
  const designTokens = useDesignTokens();
  const [editing, setEditing] = useState(false);
  const deleteManyScansMutation = useApiPost(
    "/groups/:id/test-scan-pages/bulk-discard"
  );
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });
  const testScanPages = useTestScanPages();
  const groupQuery = useGroupQuery();
  const loading =
    deleteManyScansMutation.isPending || testScanPages.isPending || _loading;

  if (editing) {
    return scans.map((scan) => <ScanWithFields scan={scan} />);
  }

  return (
    <Column
      style={{
        gap: 8,
        padding: 8,
        backgroundColor: designTokens.colors.surface.Background,
        border: `1px solid ${designTokens.colors.border.subdued}`,
        borderRadius: 12,
        position: "relative",
        opacity: loading ? 0.5 : 1,
      }}
    >
      {loading && (
        <Row
          style={{
            position: "absolute",
            inset: 0,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Loader />
        </Row>
      )}

      <Row gap={8}>
        {scans.map((scan) => (
          <ScanImage scan={scan} />
        ))}
      </Row>
      <Row
        style={{
          padding: "8px 16px",
        }}
        gap={8}
      >
        <Text textStyle="14px - Medium">Page {scans[0].pageNumber}</Text>
        <Badge type="warning" icon="information-circle-contained">
          Check Potential Duplicate Pages
        </Badge>
      </Row>
      <Row gap={8}>
        <Button
          size="small"
          text="Duplicates"
          loading={deleteManyScansMutation.isPending}
          onPress={async () => {
            if (
              window.confirm(
                "Are these scans duplicates of the same page for the same student?"
              )
            ) {
              await deleteManyScansMutation.mutateAsync({
                body: {
                  pageIds: scans.slice(1).map((scan) => scan.id),
                },
                params: {
                  id: params.id,
                },
              });
              testScanPages.refetch();
              groupQuery.refetch();
            }
          }}
        />
        <Button
          size="small"
          text="Not Duplicates"
          onPress={() => setEditing(true)}
        />
      </Row>
    </Column>
  );
}
