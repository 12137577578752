import { DateTime } from "luxon";
import stripTimeZone from "./strip-time-zone";

const displayDate = (date: Date | null | undefined | string): string => {
  if (!date) {
    return "";
  } else {
    const dateAsDate = new Date(date);
    const dateWithoutTime = stripTimeZone(dateAsDate);

    const month = dateWithoutTime.toLocaleString("default", {
      month: "short",
    });
    const day = dateWithoutTime.getDate();
    const year = dateWithoutTime.getFullYear();

    return `${month} ${day}, ${year}`;
  }
};

export const displayDateNoYear = (
  date: Date | null | undefined | string
): string => {
  if (!date) {
    return "";
  }

  const dateAsDate = new Date(date);
  return DateTime.fromJSDate(dateAsDate, { zone: "UTC" }).toFormat("MMM d");
};

export default displayDate;

export const displayTime = (date: Date | null | undefined | string): string => {
  if (!date) {
    return "";
  } else {
    const dateAsDate =
      typeof date === "string" ? new Date(date + "Z") : new Date(date);

    // Extracting hours and minutes
    let hours = dateAsDate.getHours();
    const minutes = dateAsDate.getMinutes();

    // Determining AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";
    // Converting hours to 12-hour format and removing leading zero
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Formatting minutes to ensure two digits. For example, "5" becomes "05"
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${hours}:${formattedMinutes} ${ampm}`;
  }
};
