import { Text, Button, Input, useDesignTokens } from "@gradience/ui";
import { useState } from "react";
import { isApiError, useApiPost } from "../lib/api";
import AuthChrome from "../components/auth-chrome";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/request-password-reset")({
  component: RequestPasswordReset,
});

function RequestPasswordReset() {
  const designTokens = useDesignTokens();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<{
    type: "success" | "error";
    message: string;
  }>();
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const requestPasswordesetMutation = useApiPost(
    "/auth/password-reset-request",
    {},
    {
      onSuccess: ({ message }) => {
        setMessage({
          type: "success",
          message,
        });
        setButtonEnabled(false);
      },
      onError: (error) => {
        const message = isApiError(error)
          ? (error.response?.data.errors[0].message ?? "Something went wrong.")
          : "Something went wrong. Please contact support.";

        setMessage({
          type: "error",
          message,
        });
      },
    }
  );

  return (
    <AuthChrome>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          requestPasswordesetMutation.mutate({
            body: {
              email,
            },
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text textStyle="headingLarge">Reset Password</Text>
        <Input
          label="Email"
          name="email"
          value={email}
          placeholder="Email Address"
          setValue={setEmail}
          required
        />
        {message && (
          <Text
            textStyle="strong"
            style={{
              color:
                designTokens.colors.text[
                  message.type === "success" ? "default" : "brand"
                ],
            }}
          >
            {message.message}
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text="Request Reset"
            size="large"
            type="submit"
            disabled={!buttonEnabled}
            loading={requestPasswordesetMutation.isPending}
          />
          <Text textStyle="caption" to="/log-in">
            Remembered? <b>Sign In</b>
          </Text>
        </div>
      </form>
    </AuthChrome>
  );
}

export default RequestPasswordReset;
