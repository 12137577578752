import {
  Text,
  Button,
  Card,
  Column,
  Row,
  useDesignTokens,
  Icon,
  Badge,
  Loader,
  Divider,
  Checkbox,
  OptionContainer,
  Tooltip,
  DropdownButton,
} from "@gradience/ui";
import {
  createFileRoute,
  Link,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { ReactNode } from "react";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import PageChrome from "../../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../../lib/api";
import { useHover } from "@uidotdev/usehooks";
import { Group } from "@gradience/api-types";
import displayPaymentMethod from "../../../domain/display-payment-method";
import formatCurrency from "../../../lib/format-currency";
import {
  useLatestTest,
  useSelectedTest,
} from "../../../domain/use-latest-test";
import BillingMethodModal from "./billing-method-modal";
import { isDatePast } from "../../../lib/date-utils";
import { useHasRole, useIsAdmin } from "../../../lib/auth";
import { DateTime } from "luxon";
import { displayDateNoYear } from "../../../lib/display-date";

const modals = ["billing-method"] as const;
type Modal = (typeof modals)[number];
type HomeSearch = {
  modal?: Modal;
};

export const Route = createFileRoute("/$test-slug/home/")({
  component: DashboardHome,
  validateSearch: (search): HomeSearch => {
    const validatedSearch: HomeSearch = {};

    if (modals.find((v) => v === search.modal)) {
      validatedSearch.modal = search.modal as Modal;
    }

    return validatedSearch;
  },
});

const parseDate = (date: string | undefined) => {
  if (!date) {
    return undefined;
  }

  return new Date(date);
};

function DashboardHome(): ReactNode {
  const designTokens = useDesignTokens();
  const selectedTest = useSelectedTest();
  const tests = useApiQuery("/tests", {});
  const groups = useApiQuery(
    "/groups",
    {},
    {},
    { testId: selectedTest.data?.id }
  );
  const school = useApiQuery("/school", {});
  const loading = selectedTest.isLoading || groups.isLoading;
  const paymentMethodDisplayed = school.data
    ? displayPaymentMethod(school.data)
    : null;
  const search = useSearch({ from: "__root__" });

  const numberOfStudents =
    groups.data?.data.reduce(
      (acc, group) => acc + (group.numberOfStudents ?? 0),
      0
    ) ?? 0;

  const navigate = useNavigate({
    from: "/",
  });

  const latestTest = useLatestTest();

  const hasFullAccess = useIsAdmin();

  return (
    <>
      <BillingMethodModal />
      <PageChrome loading={loading}>
        <Column
          style={{
            gap: 48,
            flexGrow: 1,
          }}
        >
          <Row gap={16}>
            <Column gap={4} style={{ flex: 1 }}>
              <Text textStyle="32px - Bold">
                Welcome to the {selectedTest.data?.name}!
              </Text>
              <Text textStyle="14px - Semibold">
                {new Date().toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Text>
            </Column>
            <DropdownButton
              text={`ULE ${
                tests.data?.data.find((t) => t.id === search.test)
                  ?.yearIdentifier ?? selectedTest.data?.yearIdentifier
              }`}
              onSelect={(value) => {
                navigate({
                  to: "/$test-slug/home",
                  params: {
                    "test-slug": value,
                  },
                });
              }}
              options={
                tests.data?.data.map((test) => ({
                  label: `ULE ${test.yearIdentifier}`,
                  value: test.yearIdentifier,
                })) ?? []
              }
            />
          </Row>

          <Row
            gap={40}
            style={{
              alignItems: "flex-start",
            }}
          >
            <Column gap={20} style={{ flex: 1 }}>
              <Card style={{ flex: 1 }}>
                <Row
                  gap={16}
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <IconContainer>
                    <Icon
                      name="user-profiles-02"
                      size={24}
                      color={designTokens.colors.icon.brand}
                    />
                  </IconContainer>
                  <Column gap={4}>
                    <Text textStyle="20px - Bold">Groups</Text>
                    <Text textStyle="14px - Medium">
                      Create groups for each class section at your school that
                      will be taking the ULE.
                    </Text>
                  </Column>
                </Row>
                {groups.data?.data.length ? (
                  <Column>
                    {groups.data.data.map((group, index) => (
                      <GroupRow
                        group={group}
                        key={group.id}
                        isLast={index === groups.data!.data.length - 1}
                      />
                    ))}
                  </Column>
                ) : null}
                {isDatePast(selectedTest.data?.configurationDateEnd) ? null : (
                  <Button
                    text="Create New Group"
                    leadingIcon="plus-01"
                    variant="subdued"
                    size="medium"
                    linkProps={{
                      search: {
                        modal: "create-group",
                      },
                    }}
                  />
                )}
              </Card>
              {hasFullAccess && (
                <Card style={{ flex: 1 }}>
                  {!school.data || !selectedTest.data ? (
                    <Loader />
                  ) : (
                    <>
                      {selectedTest.data?.id === latestTest.data?.id ? (
                        <Row
                          gap={16}
                          style={{
                            alignItems: "flex-start",
                          }}
                        >
                          <IconContainer>
                            <Icon
                              name="card-02"
                              size={24}
                              color={designTokens.colors.icon.brand}
                            />
                          </IconContainer>
                          <Column gap={4} style={{ flex: 1 }}>
                            <Text textStyle="20px - Bold">My Order</Text>
                            <Text textStyle="14px - Medium">
                              {paymentMethodDisplayed
                                ? `Your ${paymentMethodDisplayed} will be charged on or around Mar 1, 2025`
                                : "Add your card and approve billing to register for the ULE"}
                            </Text>
                          </Column>
                          {school.data.paymentMethod ? (
                            <Button
                              text="Edit Billing"
                              variant="subdued"
                              linkProps={{
                                search: {
                                  modal: "billing-method",
                                },
                              }}
                            />
                          ) : null}
                        </Row>
                      ) : school.data.receiptUrl ? (
                        <Row
                          gap={16}
                          style={{
                            alignItems: "flex-start",
                          }}
                        >
                          <IconContainer>
                            <Icon
                              name="card-02"
                              size={24}
                              color={designTokens.colors.icon.brand}
                            />
                          </IconContainer>
                          <Column gap={4} style={{ flex: 1 }}>
                            <Text textStyle="20px - Bold">My Order</Text>
                            <Text textStyle="14px - Medium">
                              Your order has been paid for.
                            </Text>
                          </Column>
                          <Button
                            leadingIcon="download-02"
                            text="Receipt"
                            variant="subdued"
                            to={school.data.receiptUrl}
                          />
                        </Row>
                      ) : null}
                      {paymentMethodDisplayed ? (
                        <Column gap={16}>
                          <Column gap={8}>
                            <Row>
                              <Text
                                textStyle="14px - Medium"
                                style={{ flex: 1 }}
                              >
                                Students
                              </Text>
                              <Text textStyle="14px - Medium">
                                {numberOfStudents}
                              </Text>
                            </Row>
                            <Row>
                              <Text
                                textStyle="14px - Medium"
                                style={{ flex: 1 }}
                              >
                                Price per student
                              </Text>
                              <Text textStyle="14px - Medium">
                                {formatCurrency(
                                  selectedTest.data.pricePerStudent
                                )}
                              </Text>
                            </Row>
                          </Column>
                          <Divider />
                          <Column gap={8}>
                            <Row>
                              <Text
                                textStyle="14px - Medium"
                                style={{ flex: 1 }}
                              >
                                Subtotal
                              </Text>
                              <Text textStyle="14px - Medium">
                                {formatCurrency(
                                  numberOfStudents *
                                    selectedTest.data.pricePerStudent
                                )}
                              </Text>
                            </Row>
                            <Row>
                              <Text
                                textStyle="14px - Medium"
                                style={{ flex: 1 }}
                              >
                                Exam Fee
                              </Text>
                              <Text textStyle="14px - Medium">
                                {formatCurrency(
                                  selectedTest.data.testProcessingFee
                                )}
                              </Text>
                            </Row>
                            <Row>
                              <Text
                                textStyle="16px - Semibold"
                                style={{ flex: 1 }}
                              >
                                Order Total
                              </Text>
                              <Text textStyle="16px - Semibold">
                                {numberOfStudents
                                  ? formatCurrency(
                                      numberOfStudents *
                                        selectedTest.data.pricePerStudent +
                                        selectedTest.data.testProcessingFee
                                    )
                                  : // If there are no students, the total is $0
                                    formatCurrency(0)}
                              </Text>
                            </Row>
                          </Column>
                        </Column>
                      ) : (
                        <Button
                          text="Add Billing Info"
                          leadingIcon="card-02"
                          variant="subdued"
                          size="medium"
                          linkProps={{
                            search: {
                              modal: "billing-method",
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </Card>
              )}
            </Column>
            <TodoList />
          </Row>
        </Column>
      </PageChrome>
    </>
  );
}

function TodoList() {
  const shouldShow = useHasRole("ADMIN");

  const selectedTest = useSelectedTest();
  const latestTest = useLatestTest();
  const udpateTodo = useApiPost("/todo-items", {});
  const todosQuery = useApiQuery(
    "/todo-items",
    {},
    {
      enabled: shouldShow,
    }
  );
  const designTokens = useDesignTokens();
  const school = useApiQuery("/school", {});

  if (!shouldShow) {
    return null;
  }

  const checklistItems: {
    title: string;
    todo: {
      done?: boolean;
      text: string;
      key?: string;
      explanation?: string;
    }[];
    startDate?: Date | undefined;
    endDate?: Date | undefined;
  }[] = [
    {
      title: "Set Up",
      todo: [
        {
          text: "Create all of your groups",
          key: "create-all-of-your-groups",
        },
        {
          text: "Add students to your groups",
          key: "add-students-to-your-groups",
        },
        {
          text: "Add billing info",
          done: school.data?.paymentMethod ? true : false,
          explanation: "Add payment information to mark this task as complete",
        },
      ],
      endDate: selectedTest.data?.configurationDateEnd
        ? new Date(selectedTest.data.configurationDateEnd)
        : undefined,
    },
    {
      title: "Configure",
      startDate: parseDate(selectedTest.data?.configurationDateStart),
      endDate: parseDate(selectedTest.data?.configurationDateEnd),
      todo: [
        {
          text: "Configure tests for each group",
          key: "configure-tests-for-each-group",
        },
        {
          text: "Print study guides for each group",
          key: "print-study-guides-for-each-group",
        },
      ],
    },
    {
      title: "Administer",
      startDate: parseDate(selectedTest.data?.administrationDateStart),
      endDate: parseDate(selectedTest.data?.administrationDateEnd),
      todo: [
        {
          text: "Print your tests 24 hours before your test date",
          key: "print-tests-24-hours-before-date",
        },
        {
          text: "Administer your tests",
          key: "administer-your-tests",
        },
      ],
    },
    {
      title: "Upload & Review",
      startDate: parseDate(selectedTest.data?.reviewDateStart),
      endDate: parseDate(selectedTest.data?.reviewDateEnd),
      todo: [
        {
          text: "Scan tests for each group",
          key: "scan-tests-for-each-group",
        },
        {
          text: "Upload tests to each group’s page",
          key: "upload-tests-to-group-page",
        },
        {
          text: "Review Test Grading",
          key: "review-test-grading",
        },
      ],
    },
    {
      title: "View Results",
      startDate: parseDate(selectedTest.data?.completionDate),
      todo: [
        {
          text: "Go to the results page and view your results!",
          key: "view-results-page",
        },
      ],
    },
  ];

  return latestTest.data?.id === selectedTest.data?.id ? (
    <Column
      style={{
        flexBasis: 347,
      }}
    >
      <Card>
        <Column gap={24}>
          <Row
            gap={16}
            style={{
              alignItems: "flex-start",
            }}
          >
            <IconContainer>
              <Icon
                name="checklist"
                size={24}
                color={designTokens.colors.icon.brand}
              />
            </IconContainer>
            <Column gap={4}>
              <Text textStyle="20px - Bold">My ULE Checklist</Text>
              <Text textStyle="14px - Medium">
                Use the following checklist to stay on top of tasks and
                deadlines.
              </Text>
            </Column>
          </Row>
        </Column>
        <Column gap={16}>
          {checklistItems.map(({ startDate, endDate, title, todo }) => {
            const active =
              isDatePast(startDate ?? new Date("2000-01-01"), true) &&
              !isDatePast(endDate ?? new Date("2100-01-01"), false);

            let dateText: string;

            if (startDate && endDate) {
              dateText = `${displayDateNoYear(startDate)} - ${displayDateNoYear(endDate)}`;
            } else if (startDate) {
              dateText = `After ${displayDateNoYear(startDate)}`;
            } else if (endDate) {
              dateText = `Before ${displayDateNoYear(endDate)}`;
            } else {
              dateText = "No deadline";
            }
            return (
              <Row
                gap={12}
                style={{
                  opacity: active ? 1 : 0.5,
                }}
                key={title}
              >
                <span
                  style={{
                    width: 4,
                    borderRadius: 80,
                    backgroundColor: active
                      ? designTokens.colors.misc.Brand
                      : designTokens.colors.border.subdued,
                  }}
                />
                <Column gap={8} style={{ flex: 1 }}>
                  <Row
                    gap={4}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Text textStyle="18px - Bold" style={{ flex: 1 }}>
                      {title}
                    </Text>
                    <Badge type={active ? "primary" : undefined}>
                      {dateText}
                    </Badge>
                  </Row>
                  <Column>
                    {todo.map(({ done, text, explanation, key }) => {
                      const isDone =
                        done ??
                        todosQuery.data?.data.some(
                          (todo) => todo.key === key && todo.done
                        );

                      return (
                        <Tooltip
                          content={isDone ? undefined : explanation}
                          key={key ?? text}
                        >
                          <OptionContainer
                            disabled={!active || todosQuery.isLoading}
                            style={{
                              padding: 8,
                              alignItems: "flex-start",
                              textAlign: "start",
                            }}
                            $active={false}
                            onClick={async () => {
                              if (!key) {
                                return;
                              }

                              await udpateTodo.mutateAsync({
                                body: {
                                  key,
                                  done: !isDone,
                                },
                              });
                              todosQuery.refetch();
                            }}
                          >
                            <Checkbox
                              value={isDone ?? false}
                              disabled={!active || todosQuery.isLoading}
                              onChange={async (value) => {
                                await udpateTodo.mutateAsync({
                                  body: {
                                    key: text,
                                    done: value,
                                  },
                                });
                                todosQuery.refetch();
                              }}
                            />
                            <Text textStyle="14px - Medium">{text}</Text>
                          </OptionContainer>
                        </Tooltip>
                      );
                    })}
                  </Column>
                </Column>
              </Row>
            );
          })}
        </Column>
      </Card>
    </Column>
  ) : null;
}

function GroupRow({ group, isLast }: { group: Group; isLast: boolean }) {
  const [ref, hovered] = useHover();
  const designTokens = useDesignTokens();
  const test = useSelectedTest();

  return (
    <Link
      ref={ref}
      search={(prev) => (prev.test ? { test: prev.test } : {})}
      to="/$test-slug/groups/$id"
      params={{
        "test-slug": test.data?.yearIdentifier ?? "latest",
        id: group.id,
      }}
      style={{
        padding: "20px 16px",
        borderBottom:
          isLast && isDatePast(test.data?.configurationDateEnd)
            ? "0"
            : "1px solid #E3E3E3",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        textDecoration: "none",
      }}
    >
      <Column gap={8} style={{ flex: 1 }}>
        <Text textStyle="18px - Bold">{group.name}</Text>
        <Row gap={8}>
          <Badge icon="user-profile-group">{`${group.numberOfStudents} Students`}</Badge>
          <Badge icon="calendar-07">
            {`Test Date: ${DateTime.fromISO(group.testDate!).toLocaleString(
              DateTime.DATE_FULL
            )}`}
          </Badge>
        </Row>
      </Column>
      <Icon
        name="arrow-right"
        onClick={() => {}}
        style={{
          backgroundColor: hovered
            ? designTokens.colors.surface.Disabled
            : "transparent",
        }}
      />
    </Link>
  );
}
