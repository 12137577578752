var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
import Icon from "./icon";
import { Row } from "./layout";
import Text from "./text";
var Badge = function (_a) {
    var status = _a.type, children = _a.children, icon = _a.icon, style = _a.style;
    var backgroundColor;
    var textColor;
    var iconColor;
    var designTokens = useDesignTokens();
    switch (status) {
        case "primary":
            backgroundColor = designTokens.colors.surface.Brand;
            textColor = designTokens.colors.text.brand;
            iconColor = designTokens.colors.icon.brand;
            break;
        case "secondary":
            backgroundColor = designTokens.colors.surface.Dark;
            textColor = designTokens.colors.text.inverted;
            iconColor = designTokens.colors.icon.white;
            break;
        case "gold":
            backgroundColor = "#ffc80d";
            textColor = "black";
            iconColor = "black";
            break;
        case "silver":
            backgroundColor = "#dcdcdc";
            textColor = "black";
            iconColor = "black";
            break;
        case "minimal":
            backgroundColor = "transparent";
            textColor = "black";
            iconColor = "black";
            break;
        case "warning":
            backgroundColor = "#FFF1DA";
            textColor = designTokens.colors.text.default;
            iconColor = designTokens.colors.icon.dark;
            break;
        case "success":
            backgroundColor = "#E1FAEA";
            textColor = designTokens.colors.text.default;
            iconColor = designTokens.colors.icon.dark;
            break;
        default:
            backgroundColor = designTokens.colors.gray[8];
            textColor = designTokens.colors.text.default;
            iconColor = designTokens.colors.icon.dark;
            break;
    }
    return (_jsxs(Row, { style: __assign({ paddingLeft: 8, paddingRight: 8, paddingTop: 2, paddingBottom: 2, borderRadius: 8, backgroundColor: backgroundColor, alignItems: "center" }, style), gap: 4, children: [icon && _jsx(Icon, { name: icon, size: "small", color: iconColor }), _jsx(Text, { textStyle: "caption", style: {
                    whiteSpace: "nowrap",
                    color: textColor,
                }, children: children })] }));
};
export default Badge;
