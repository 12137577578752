import { Column, Loader, Row, useDesignTokens } from "@gradience/ui";
import { ScanImage } from "./components";
import { ReactNode } from "react";
import { Scan } from "./utils";

export function ScanCard({
  scan,
  children,
  loading,
}: {
  scan: Scan;
  children?: ReactNode;
  loading?: boolean;
}) {
  const designTokens = useDesignTokens();

  return (
    <Column
      style={{
        gap: 8,
        padding: 8,
        backgroundColor: designTokens.colors.surface.Background,
        border: `1px solid ${designTokens.colors.border.subdued}`,
        borderRadius: 12,
        position: "relative",
        opacity: loading ? 0.5 : 1,
      }}
    >
      {loading && (
        <Row
          style={{
            position: "absolute",
            inset: 0,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Loader />
        </Row>
      )}
      <ScanImage scan={scan} />
      {children}
    </Column>
  );
}
