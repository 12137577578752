import { Text, Button, Input, useDesignTokens } from "@gradience/ui";
import { useLogin } from "../lib/auth";
import { useEffect, useState } from "react";
import AuthChrome from "../components/auth-chrome";
import { isApiError } from "../lib/api";
import { createFileRoute, useSearch } from "@tanstack/react-router";

type LoginRouteSearch = {
  userImpersonationEmail?: string;
  userImpersonationToken?: string;
};
export const Route = createFileRoute("/log-in")({
  component: LogIn,
  validateSearch: (search): LoginRouteSearch => {
    const validatedSearch: LoginRouteSearch = {};

    if (typeof search.userImpersonationEmail === "string") {
      validatedSearch.userImpersonationEmail = search.userImpersonationEmail;
    }

    if (typeof search.userImpersonationToken === "string") {
      validatedSearch.userImpersonationToken = search.userImpersonationToken;
    }

    return search;
  },
});

function LogIn() {
  const designTokens = useDesignTokens();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { userImpersonationEmail, userImpersonationToken } = useSearch({
    from: "/log-in",
  });

  const logInMutation = useLogin();

  useEffect(() => {
    if (userImpersonationEmail && userImpersonationToken) {
      logInMutation.mutate({
        body: {
          username: userImpersonationEmail,
          password: userImpersonationToken,
        },
      });
    }
  }, [logInMutation, userImpersonationEmail, userImpersonationToken]);

  return (
    <AuthChrome>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          logInMutation.mutate({ body: { username: email, password } });
        }}
      >
        <Text textStyle="headingLarge">Log In</Text>
        <Input
          label="Email"
          name="email"
          required
          value={email}
          setValue={setEmail}
        />
        <Input
          label="Password"
          name="password"
          type="password"
          required
          value={password}
          setValue={setPassword}
        />
        {isApiError(logInMutation.error) && (
          <Text
            textStyle="strong"
            style={{
              color: designTokens.colors.text.brand,
            }}
          >
            {logInMutation.error?.response?.data.errors[0].message}
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text="Log In"
            size="large"
            type="submit"
            loading={logInMutation.isPending}
          />
          <Text textStyle="caption" to="/sign-up">
            Don’t have an account? <b>Sign Up</b>
          </Text>
          <Text textStyle="caption" to="/request-password-reset">
            Forgot your password? <b>Reset it</b>
          </Text>
        </div>
      </form>
    </AuthChrome>
  );
}
