import {
  Badge,
  Button,
  Column,
  Icon,
  Loader,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useState } from "react";
import UploadTestsModal from "../../upload-tests-modal";
import { useParams } from "@tanstack/react-router";
import { useGroupQuery, useTestScanPages } from "./utils";

export function EmptyScanCard({
  page,
  loading,
}: {
  page: number;
  loading?: boolean;
}) {
  const designTokens = useDesignTokens();
  const [uploading, setUploading] = useState(false);
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });
  const testScanPagesQuery = useTestScanPages();
  const groupQuery = useGroupQuery();

  return (
    <>
      <UploadTestsModal
        onComplete={() => {
          testScanPagesQuery.refetch();
          groupQuery.refetch();
          setUploading(false);
        }}
        onClose={() => setUploading(false)}
        isOpen={uploading}
        groupId={params.id}
      />
      <Column
        style={{
          gap: 8,
          padding: 8,
          backgroundColor: designTokens.colors.surface.Background,
          border: `1px solid ${designTokens.colors.border.subdued}`,
          borderRadius: 12,
          position: "relative",
          opacity: loading ? 0.5 : 1,
        }}
      >
        {loading && (
          <Row
            style={{
              position: "absolute",
              inset: 0,
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            <Loader />
          </Row>
        )}
        <span
          style={{
            height: 315,
            width: 244,
            backgroundColor: designTokens.colors.gray[4],
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setUploading(true);
          }}
        >
          <Icon name="upload-03" />
        </span>
        <Row style={{ padding: "8px 16px", gap: 8 }}>
          <Text textStyle="14px - Medium">Page {page}</Text>
          <Badge type="warning" icon="information-circle-contained">
            Missing
          </Badge>
        </Row>
        <Button
          text="Upload Page"
          leadingIcon="upload-03"
          onPress={() => setUploading(true)}
        />
      </Column>
    </>
  );
}
