import {
  Button,
  Column,
  Icon,
  Input,
  Loader,
  Row,
  Text,
  UnstyledButtonWrapper,
  useDesignTokens,
} from "@gradience/ui";
import { useParams } from "@tanstack/react-router";
import { useApiPut, useApiQuery } from "../../../../../lib/api";
import { Scan, useGroupQuery, useTestScanPages } from "./utils";
import { ScanImage } from "./components";
import { useState } from "react";

export function ScanWithFields({
  scan,
  loading: _loading,
}: {
  scan: Scan;
  loading?: boolean;
}) {
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });
  const designTokens = useDesignTokens();
  const students = useApiQuery("/students", {}, undefined, {
    groupId: params.id,
  });

  const putPageMutation = useApiPut("/groups/:id/test-scan-pages/:pageId");
  const testScanPagesQuery = useTestScanPages();
  const groupQuery = useGroupQuery();

  const loading = putPageMutation.isPending || _loading;

  const [studentId, setStudentId] = useState<string | undefined>(
    scan.studentId
  );
  const [pageNumber, setPageNumber] = useState<number | undefined>(
    scan.pageNumber
  );

  return (
    <Column
      style={{
        gap: 8,
        padding: 8,
        backgroundColor: designTokens.colors.surface.Background,
        border: `1px solid ${designTokens.colors.border.subdued}`,
        borderRadius: 12,
        position: "relative",
      }}
    >
      {loading && (
        <Row
          style={{
            position: "absolute",
            inset: 0,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Loader />
        </Row>
      )}
      <ScanImage scan={scan} />
      <Column
        style={{
          padding: 8,
          gap: 12,
        }}
      >
        <Column gap={8}>
          <Row gap={8}>
            <Text textStyle="14px - Bold" style={{ flex: 1 }}>
              Identify Page
            </Text>
            <UnstyledButtonWrapper
              style={{
                flexDirection: "row",
                gap: 4,
                display: "flex",
                alignItems: "center",
                cursor: putPageMutation.isPending ? "not-allowed" : "pointer",
              }}
              onClick={async () => {
                await putPageMutation.mutateAsync({
                  body: {
                    isUpsideDown: !scan.isUpsideDown,
                  },
                  params: {
                    id: params.id,
                    pageId: scan.id,
                  },
                });
                testScanPagesQuery.refetch();
              }}
            >
              <Icon
                size="small"
                name="arrow-rotate-right-01"
                color={designTokens.colors.icon.dark}
              />
              <Text
                textStyle="caption"
                style={{
                  color: putPageMutation.isPending
                    ? designTokens.colors.text.subdued
                    : designTokens.colors.text.default,
                }}
              >
                Rotate
              </Text>
            </UnstyledButtonWrapper>
          </Row>
          <Input
            type="select"
            options={[
              { label: "---", value: "" },
              ...(students.data?.data.map((student) => ({
                label: `${student.firstName} ${student.lastName}`,
                value: student.id,
              })) ?? []),
            ]}
            value={studentId}
            setValue={(value) => value !== "" && setStudentId(value)}
          />
          <Input
            type="select"
            options={[
              { label: "---", value: "" },
              ...Array.from({
                length: groupQuery.data?.numberOfPagesInTest ?? 0,
              }).map((_, i) => ({
                label: `${i + 1}`,
                value: (i + 1).toString(),
              })),
            ]}
            value={pageNumber?.toString()}
            setValue={(value) => value !== "" && setPageNumber(parseInt(value))}
          />
        </Column>
        <Button
          text="Confirm"
          variant="primary"
          disabled={studentId === undefined || pageNumber === undefined}
          loading={putPageMutation.isPending}
          onPress={async () => {
            await putPageMutation.mutateAsync({
              body: {
                studentId,
                pageNumber,
              },
              params: {
                id: params.id,
                pageId: scan.id,
              },
            });
            testScanPagesQuery.refetch();
            groupQuery.refetch();
          }}
        />
        <Column gap={6}>
          <Row gap={6} style={{ alignItems: "center" }}>
            <span
              style={{
                height: 1,
                backgroundColor: designTokens.colors.border.subdued,
                flex: 1,
              }}
            />
            <Text textStyle="caption">OR</Text>
            <span
              style={{
                height: 1,
                backgroundColor: designTokens.colors.border.subdued,
                flex: 1,
              }}
            />
          </Row>
          <UnstyledButtonWrapper
            style={{ alignSelf: "center" }}
            disabled={putPageMutation.isPending}
            onClick={async () => {
              await putPageMutation.mutateAsync({
                body: {
                  isDiscarded: true,
                },
                params: {
                  id: params.id,
                  pageId: scan.id,
                },
              });

              testScanPagesQuery.refetch();
              groupQuery.refetch();
            }}
          >
            <Text
              textStyle="14px - Bold"
              style={{
                color: designTokens.colors.text.brand,
              }}
            >
              Delete Page
            </Text>
          </UnstyledButtonWrapper>
        </Column>
      </Column>
    </Column>
  );
}
