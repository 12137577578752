import {
  Column,
  CompactModal,
  FileInput,
  Icon,
  Loader,
  Progress,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useApiPost, useApiQuery } from "../../../lib/api";
import { IconContainer } from "@gradience/ui/dist/components/icon";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";

const UploadTestsModal = ({
  onComplete,
  onClose,
  isOpen,
  groupId,
}: {
  onComplete?: () => unknown;
  onClose: () => unknown;
  isOpen: boolean;
  groupId: string;
}) => {
  const getFielUploadUrl = useApiPost("/uploads/presigned-url");

  const designTokens = useDesignTokens();
  const [testScanId, setTestScanId] = useState<string | undefined>();

  const testScans = useApiQuery(
    "/groups/:id/test-scans",
    {
      id: groupId,
    },
    {
      refetchInterval: testScanId ? 1000 : false,
    }
  );

  const group = useApiQuery("/groups/:id", { id: groupId });

  const createTestScan = useApiPost("/groups/:id/test-scans");

  const uploadedTestScan = useMemo(
    () => testScans.data?.data.find((testScan) => testScan.id === testScanId),
    [testScans.data, testScanId]
  );

  const uploadToS3 = useMutation({
    mutationFn: ({
      uploadUrl,
      file,
    }: {
      uploadUrl: { presignedUrl: string; uploadId: string };
      file: File;
    }) =>
      fetch(uploadUrl.presignedUrl, {
        method: "PUT",
        body: file,
      }),
  });

  useEffect(() => {
    if (
      uploadedTestScan &&
      uploadedTestScan.numberOfPagesProcessed !== undefined &&
      uploadedTestScan.numberOfPagesProcessed === uploadedTestScan.numberOfPages
    ) {
      onComplete?.();
    }
  }, [onComplete, uploadedTestScan]);

  return (
    <CompactModal
      open={isOpen}
      close={onClose}
      fixedWidth
      style={{ width: 502 }}
    >
      {uploadToS3.isPending ||
      getFielUploadUrl.isPending ||
      createTestScan.isPending ? (
        <Loader />
      ) : (
        <Column gap={32}>
          {testScanId ? (
            <>
              <Column gap={4}>
                <Text textStyle="24px - Bold">Processing Upload</Text>
                <Text textStyle="14px - Medium">
                  You can wait while the upload processes or come back later to
                  resolve any issues with the upload.
                </Text>
              </Column>
              <Column
                style={{
                  padding: 24,
                  border: `1px solid ${designTokens.colors.border.default}`,
                  gap: 16,
                  borderRadius: 12,
                }}
              >
                <Text textStyle="14px - Semibold">
                  {uploadedTestScan?.numberOfPages === undefined
                    ? "Splitting into pages"
                    : `Processing page ${(uploadedTestScan.numberOfPagesProcessed ?? 0) + 1} of ${uploadedTestScan.numberOfPages}`}
                </Text>
                <Progress
                  progress={
                    uploadedTestScan?.numberOfPages === undefined
                      ? 0
                      : 0.2 +
                        ((uploadedTestScan.numberOfPagesProcessed ?? 0) /
                          uploadedTestScan.numberOfPages) *
                          0.8
                  }
                />
              </Column>
            </>
          ) : group.data?.uploadProcessing ? (
            <Column gap={4}>
              <Text textStyle="24px - Bold">Processing Tests</Text>
              <Text textStyle="14px - Medium">
                Please wait while we process the tests for this group. This may
                take several hours depending on how busy our servers are.
              </Text>
            </Column>
          ) : (
            <>
              <Column gap={4}>
                <Text textStyle="24px - Bold">Upload Tests</Text>
                <Text textStyle="14px - Medium">
                  Upload scans of the exams for this group.
                </Text>
              </Column>

              <Column gap={32}>
                <FileInput
                  accept="pdf"
                  onChange={async (file) => {
                    if (file) {
                      const uploadUrl = await getFielUploadUrl.mutateAsync({
                        body: {
                          filename: file.name,
                          contentType: file.type,
                        },
                      });

                      const response = await uploadToS3.mutateAsync({
                        uploadUrl,
                        file,
                      });

                      if (response.ok) {
                        const { id } = await createTestScan.mutateAsync({
                          body: {
                            uploadId: uploadUrl.uploadId,
                            fileName: file.name,
                          },
                          params: {
                            id: groupId,
                          },
                        });

                        setTestScanId(id);
                      }
                    }
                  }}
                />
                <Row gap={8} style={{ alignItems: "start" }}>
                  <IconContainer>
                    <Icon
                      name="information-circle-contained"
                      color={designTokens.colors.icon.brand}
                      size={24}
                    />
                  </IconContainer>
                  <Column gap={4}>
                    <Text textStyle="14px - Bold">Format Requirements</Text>
                    <Text textStyle="caption">
                      Upload a PDF or TIFF file. Circles should be clearly
                      visible and all pages should be in the correct
                      orientation.
                    </Text>
                  </Column>
                </Row>
              </Column>
            </>
          )}
        </Column>
      )}
    </CompactModal>
  );
};

export default UploadTestsModal;
