import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { grades } from "./add-students-modal";
import { queryKeys, useApiPut, useApiQuery } from "../../../lib/api";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { Button, Column, CompactModal, Input, Row, Text } from "@gradience/ui";
import { z } from "zod";
import { useMemo } from "react";

const EditStudentModal = () => {
  const search = useSearch({ from: "/$test-slug/groups/$id/" });
  const params = useParams({ from: "/$test-slug/groups/$id/" });
  const navigate = useNavigate({ from: "/$test-slug/groups/$id" });

  const updateStudentMutation = useApiPut("/students/:id", {
    id: search.editingStudentId ?? "",
  });
  const queryClient = useQueryClient();
  const students = useApiQuery("/students", {}, undefined, {
    groupId: params.id,
  });

  const formDefaults: {
    firstName: string | undefined;
    lastName: string | undefined;
    grade: (typeof grades)[number] | undefined;
  } = useMemo(() => {
    const student = students.data?.data.find(
      (student) => student.id === search.editingStudentId
    );
    return {
      firstName: student?.firstName,
      lastName: student?.lastName,
      grade: student?.grade,
    };
  }, [search.editingStudentId, students.data]);

  const form = useForm({
    defaultValues: formDefaults,
    onSubmit: async ({ value }) => {
      await updateStudentMutation.mutateAsync({
        body: {
          firstName: value.firstName ?? "",
          lastName: value.lastName ?? "",
          grade: value.grade!,
          skipGrading:
            students.data?.data.find(
              (student) => student.id === search.editingStudentId
            )?.skipGrading ?? false,
        },
      });

      form.reset();
      await navigate({
        search: (prev) => ({ ...prev, groupModal: undefined }),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys["/students"],
      });
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <CompactModal
      open={
        search.groupModal === "edit-student" &&
        search.editingStudentId !== undefined
      }
      close={() => {
        form.reset();
        navigate({
          search: (prev) => ({ ...prev, groupModal: undefined }),
        });
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Text textStyle="18px - Bold">Edit Student</Text>
        <div>
          <Column gap={16}>
            <Row gap={8}>
              <form.Field
                name="firstName"
                validators={{
                  onBlur: z
                    .string({
                      required_error: "Please enter a first name",
                    })
                    .min(1, "Please enter a first name"),
                }}
              >
                {(subField) => {
                  return (
                    <Input
                      inputProps={{
                        onBlur: subField.handleBlur,
                      }}
                      placeholder="First Name"
                      value={subField.state.value ?? ""}
                      setValue={subField.handleChange}
                      errorText={subField.state.meta.errors.join(", ")}
                    />
                  );
                }}
              </form.Field>
              <form.Field
                name="lastName"
                validators={{
                  onBlur: z
                    .string({
                      required_error: "Please enter a last name",
                    })
                    .min(1, "Please enter a last name"),
                }}
              >
                {(subField) => {
                  return (
                    <Input
                      placeholder="Last Name"
                      value={subField.state.value ?? ""}
                      setValue={subField.handleChange}
                      inputProps={{
                        onBlur: subField.handleBlur,
                      }}
                      errorText={subField.state.meta.errors.join(", ")}
                    />
                  );
                }}
              </form.Field>
              <form.Field
                name="grade"
                validators={{
                  onSubmit: z
                    .string({
                      required_error: "Please select a grade",
                    })
                    .min(1, "Please select a grade"),
                }}
              >
                {(subField) => {
                  return (
                    <Input
                      type="select"
                      placeholder="Last Name"
                      style={{
                        width: 108,
                      }}
                      value={subField.state.value}
                      setValue={(v) =>
                        subField.handleChange(v as (typeof grades)[number])
                      }
                      errorText={subField.state.meta.errors.join(", ")}
                      options={[
                        { value: "", label: "" },
                        ...grades.map((grade) => ({
                          value: grade,
                          label: grade,
                        })),
                      ]}
                    />
                  );
                }}
              </form.Field>
            </Row>
          </Column>
        </div>

        <Row gap={8}>
          <Button
            text="Save"
            variant="primary"
            type="submit"
            loading={updateStudentMutation.isPending}
          />
          <Button
            text="Cancel"
            linkProps={{
              search: (prev) => ({ ...prev, groupModal: undefined }),
            }}
          />
        </Row>
      </form>
    </CompactModal>
  );
};

export default EditStudentModal;
