import { Column, Icon, Modal, Row, Text, useDesignTokens } from "@gradience/ui";
import styled from "styled-components";
import { Scan } from "./utils";
import { useState } from "react";

export const Overlay = styled.button`
  position: absolute;
  inset: 0;
  border-radius: 12px;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
  }
`;

export function ViewScanModal({
  isOpen,
  onClose,
  label,
  imageUrl,
  isUpsideDown,
}: {
  isOpen: boolean;
  onClose: () => unknown;
  label: string;
  imageUrl: string;
  isUpsideDown: boolean;
}) {
  const designTokens = useDesignTokens();

  return (
    <Modal open={isOpen} close={onClose}>
      <Column gap={8}>
        <Row gap={4}>
          <Text textStyle="20px - Bold" style={{ flex: 1 }}>
            {label}
          </Text>
          <Icon name="close" onClick={onClose} />
        </Row>
        <img
          alt={label}
          src={imageUrl}
          style={{
            width: "100%",
            objectFit: "contain",
            borderRadius: 12,
            border: `1px solid ${designTokens.colors.border.subdued}`,
            transform: isUpsideDown ? "rotate(180deg)" : undefined,
          }}
        />
      </Column>
    </Modal>
  );
}

export function ScanImage({ scan }: { scan: Scan }) {
  const [viewingFullSize, setViewingFullSize] = useState(false);
  const designTokens = useDesignTokens();

  return (
    <>
      <ViewScanModal
        isOpen={viewingFullSize}
        onClose={() => setViewingFullSize(false)}
        label={`Scan ${scan.pageNumber}`}
        imageUrl={scan.imageUrl}
        isUpsideDown={scan.isUpsideDown}
      />
      <span
        style={{
          position: "relative",
          borderRadius: 8,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <img
          alt="Unidentified Scan"
          src={scan.imageUrl}
          style={{
            width: 242,
            objectFit: "cover",
            borderRadius: 12,
            border: `1px solid ${designTokens.colors.border.subdued}`,
            transform: scan.isUpsideDown ? "rotate(180deg)" : undefined,
          }}
        />
        <Overlay onClick={() => setViewingFullSize(true)}>
          <Row
            gap={8}
            style={{
              alignItems: "center",
            }}
          >
            <Icon
              name="arrow-expand-03"
              size={24}
              color={designTokens.colors.icon.white}
            />
            <Text
              textStyle="14px - Bold"
              style={{
                color: designTokens.colors.text.inverted,
              }}
            >
              View Full Size
            </Text>
          </Row>
        </Overlay>
      </span>
    </>
  );
}
