import { DateTime } from "luxon";

export function isDatePast(
  date?: string | Date,
  endOfDay = false
): boolean | undefined {
  if (!date) {
    return undefined;
  }

  const dateAsDate = new Date(date);
  const isoDateStr = dateAsDate.toISOString().split("T")[0];

  const compareDate = DateTime.fromISO(isoDateStr).startOf("day");
  let today = DateTime.local().startOf("day");

  if (endOfDay) {
    today = today.endOf("day");
  }

  return compareDate < today;
}
