import { createFileRoute } from "@tanstack/react-router";
import {
  Badge,
  Button,
  Column,
  Modal,
  Row,
  Text,
  Tooltip,
  UnstyledButtonWrapper,
  useDesignTokens,
} from "@gradience/ui";
import Icon, { IconContainer } from "@gradience/ui/dist/components/icon";
import { useApiPut, useApiQuery } from "../../../../../lib/api";
import { useParams } from "@tanstack/react-router";
import { useMemo, useRef, useState } from "react";
import PageChrome from "../../../../../components/page-chrome";
import { useGroupQuery, useTestScanPages } from "./utils";
import { ScanWithFields } from "./scan-with-fields";
import { ScanCard } from "./scan-card";
import { StudentRow } from "./student-row";

export const Route = createFileRoute("/$test-slug/groups/$id/inspect-scans/")({
  component: InspectScans,
});

function InspectScans() {
  const designTokens = useDesignTokens();
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  const testScanPagesQuery = useTestScanPages();

  const groupQuery = useApiQuery("/groups/:id", {
    id: params.id,
  });
  const students = useApiQuery("/students", {}, undefined, {
    groupId: params.id,
  });

  return (
    <PageChrome>
      <Column style={{ gap: 40, flex: 1 }}>
        <Row
          gap={16}
          style={{
            alignItems: "flex-start",
          }}
        >
          <Row
            gap={8}
            style={{
              alignItems: "flex-start",
              flex: 1,
            }}
          >
            <Icon
              name="arrow-left"
              to={`/${params["test-slug"]}/groups/${params.id}`}
            />
            <Column gap={8}>
              <Text textStyle="32px - Bold">Inspect Upload</Text>
              <Text textStyle="14px - Semibold">
                {groupQuery.data?.name} • {groupQuery.data?.instructorFirstName}{" "}
                {groupQuery.data?.instructorLastName}
              </Text>
            </Column>
          </Row>
          <Tooltip
            content="You must resolve issues for all students before continuing, or you can go back and finish at a later time."
            enabled={groupQuery.data?.uploadWarning}
          >
            <Button
              variant="primary"
              text="Finish"
              disabled={groupQuery.data?.uploadWarning}
              to={`/${params["test-slug"]}/groups/${params.id}`}
              style={{ display: "block" }}
            />
          </Tooltip>
        </Row>
        <Column
          style={{
            gap: 24,
            border: `1px solid ${designTokens.colors.border.subdued}`,
            borderRadius: 16,
            backgroundColor: designTokens.colors.surface.White,
          }}
        >
          <Row
            gap={16}
            style={{
              alignItems: "flex-start",
              padding: 24,
            }}
          >
            <IconContainer>
              <Icon
                name="file-question-02"
                color={designTokens.colors.icon.brand}
                size={24}
              />
            </IconContainer>
            <Column gap={4}>
              <Text textStyle="20px - Bold">Inspect Upload</Text>
              <Text textStyle="14px - Medium">
                Please review any issues below, or press finish once all pages
                for each student are present.
              </Text>
            </Column>
          </Row>
          <Column>
            <Row>
              <Column style={{ padding: "16px 24px", flex: 1 }}>
                <Text textStyle="subHeading">Name</Text>
              </Column>
              <Column style={{ padding: "16px 24px", flex: 1 }}>
                <Text textStyle="subHeading">Pages</Text>
              </Column>
              <Column
                style={{
                  padding: "16px 24px",
                  width: 60,
                  boxSizing: "border-box",
                }}
              />
            </Row>
            {testScanPagesQuery.data?.data.find(
              (p) => p.studentId === undefined && p.discardedAt === undefined
            ) && <UnidentifiedPages />}
            {students.data?.data.map((s) => <StudentRow student={s} />)}
            {testScanPagesQuery.data?.data.find(
              (p) => p.discardedAt !== undefined
            ) ? (
              <DiscardedPages />
            ) : null}
          </Column>
        </Column>
      </Column>
    </PageChrome>
  );
}

function UnidentifiedPages() {
  const [_expanded, setExpanded] = useState<boolean>();
  const expanded = _expanded ?? false;

  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  const designTokens = useDesignTokens();
  const groupQuery = useApiQuery("/groups/:id", {
    id: params.id,
  });

  const testScanPagesQuery = useTestScanPages();
  const numberOfPagesUploaded = testScanPagesQuery.data?.data.filter(
    (p) => p.studentId === undefined && p.discardedAt === undefined
  ).length;

  const pagesRowRef = useRef<HTMLDivElement>(null);

  return (
    <Column>
      <Row
        style={{
          borderTop: `1px solid ${designTokens.colors.border.subdued}`,
          cursor: "pointer",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Column style={{ padding: 24, flex: 1 }}>
          <Text>Unidentified Pages</Text>
        </Column>
        <Column style={{ padding: 24, flex: 1 }}>
          <Row>
            <Badge
              type={
                numberOfPagesUploaded === 0
                  ? "minimal"
                  : numberOfPagesUploaded ===
                      groupQuery.data?.numberOfPagesInTest
                    ? "success"
                    : "warning"
              }
              icon={
                numberOfPagesUploaded === 0
                  ? "close"
                  : numberOfPagesUploaded ===
                      groupQuery.data?.numberOfPagesInTest
                    ? "check-01"
                    : "information-circle-contained"
              }
            >
              {`${numberOfPagesUploaded} ${numberOfPagesUploaded === 1 ? "Page Requires" : "Pages Require"} Review`}
            </Badge>
          </Row>
        </Column>
        <Column
          style={{
            width: 60,
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon name={expanded ? "chevron-up" : "chevron-down"} />
        </Column>
      </Row>
      {expanded && (
        <div
          ref={pagesRowRef}
          style={{
            margin: 16,
            minWidth: 0,
            alignSelf: "flex-start",
            marginTop: 0,
            backgroundColor: designTokens.colors.gray[8],
            borderRadius: 16,
            padding: 24,
            gap: 16,
            overflowX: "auto",
            boxSizing: "border-box",
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Row gap={8}>
            {testScanPagesQuery.data?.data
              .filter((scan) => scan.studentId === undefined)
              .filter((scan) => scan.discardedAt === undefined)
              .sort((a, b) => a.pageNumber - b.pageNumber)
              .map((scan) => (
                <ScanWithFields
                  scan={scan}
                  loading={testScanPagesQuery.isPending}
                />
              ))}
            <span style={{ width: 16, flexShrink: 0 }} />
          </Row>
          <Row
            style={{
              position: "sticky",
              left: 0,
            }}
          >
            <button
              style={{
                appearance: "none",
                border: "none",
                backgroundColor: designTokens.colors.surface.Background,
                padding: 4,
                borderRadius: 8,
                lineHeight: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                if (pagesRowRef.current) {
                  pagesRowRef.current.scrollTo({
                    left: pagesRowRef.current.scrollLeft - 226,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <Icon name="arrow-left" />
            </button>
            <span style={{ flex: 1 }} />
            <button
              style={{
                appearance: "none",
                border: "none",
                backgroundColor: designTokens.colors.surface.Background,
                padding: 4,
                borderRadius: 8,
                lineHeight: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                if (pagesRowRef.current) {
                  pagesRowRef.current.scrollTo({
                    left: pagesRowRef.current.scrollLeft + 226,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <Icon name="arrow-right" />
            </button>
          </Row>
        </div>
      )}
    </Column>
  );
}

function DiscardedPages() {
  const [_expanded, setExpanded] = useState<boolean>();
  const expanded = _expanded ?? false;

  const designTokens = useDesignTokens();

  const testScanPagesQuery = useTestScanPages();
  const groupQuery = useGroupQuery();
  const numberOfPagesUploaded = testScanPagesQuery.data?.data.filter(
    (p) => p.discardedAt !== undefined
  ).length;

  const pagesRowRef = useRef<HTMLDivElement>(null);

  const [viewingFullSize, setViewingFullSize] = useState<string | undefined>();
  const pageViewingFullSize = useMemo(
    () => testScanPagesQuery.data?.data.find((p) => p.id === viewingFullSize),
    [testScanPagesQuery.data, viewingFullSize]
  );

  const putPageMutation = useApiPut("/groups/:id/test-scan-pages/:pageId");
  const params = useParams({
    from: "/$test-slug/groups/$id/inspect-scans/",
  });

  return (
    <Column>
      <Modal
        open={pageViewingFullSize !== undefined}
        close={() => setViewingFullSize(undefined)}
      >
        <Column gap={8}>
          <Row gap={4}>
            <Text textStyle="20px - Bold" style={{ flex: 1 }}>
              Discarded Page
            </Text>
            <Icon name="close" onClick={() => setViewingFullSize(undefined)} />
          </Row>
          <img
            alt="Unidentified Scan"
            src={pageViewingFullSize?.imageUrl}
            style={{
              width: "100%",
              objectFit: "contain",
              borderRadius: 12,
              border: `1px solid ${designTokens.colors.border.subdued}`,
            }}
          />
        </Column>
      </Modal>
      <Row
        style={{
          borderTop: `1px solid ${designTokens.colors.border.subdued}`,
          cursor: "pointer",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Column style={{ padding: 24, flex: 1 }}>
          <Text>Discarded Pages</Text>
        </Column>
        <Column style={{ padding: 24, flex: 1 }}>
          <Row>
            <Badge icon={"trash-04"}>
              {`${numberOfPagesUploaded} ${numberOfPagesUploaded === 1 ? "Page" : "Pages"}`}
            </Badge>
          </Row>
        </Column>
        <Column
          style={{
            width: 60,
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon name={expanded ? "chevron-up" : "chevron-down"} />
        </Column>
      </Row>
      {expanded && (
        <div
          ref={pagesRowRef}
          style={{
            margin: 16,
            minWidth: 0,
            alignSelf: "flex-start",
            marginTop: 0,
            backgroundColor: designTokens.colors.gray[8],
            borderRadius: 16,
            padding: 24,
            gap: 16,
            overflowX: "auto",
            boxSizing: "border-box",
            width: "calc(100% - 32px)",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Row gap={8}>
            {testScanPagesQuery.data?.data
              .filter((scan) => scan.discardedAt !== undefined)
              .sort((a, b) => a.pageNumber - b.pageNumber)
              .map((scan) => (
                <ScanCard
                  scan={scan}
                  loading={
                    putPageMutation.isPending || testScanPagesQuery.isPending
                  }
                >
                  <UnstyledButtonWrapper
                    disabled={putPageMutation.isPending}
                    onClick={async () => {
                      await putPageMutation.mutateAsync({
                        body: {
                          isDiscarded: false,
                        },
                        params: {
                          id: params.id,
                          pageId: scan.id,
                        },
                      });

                      groupQuery.refetch();
                      testScanPagesQuery.refetch();
                    }}
                  >
                    <Text
                      textStyle="14px - Bold"
                      style={{
                        color: designTokens.colors.text.brand,
                      }}
                    >
                      Recover
                    </Text>
                  </UnstyledButtonWrapper>
                </ScanCard>
              ))}
            <span style={{ width: 16, flexShrink: 0 }} />
          </Row>
          <Row
            style={{
              position: "sticky",
              left: 0,
            }}
          >
            <button
              style={{
                appearance: "none",
                border: "none",
                backgroundColor: designTokens.colors.surface.Background,
                padding: 4,
                borderRadius: 8,
                lineHeight: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                if (pagesRowRef.current) {
                  pagesRowRef.current.scrollTo({
                    left: pagesRowRef.current.scrollLeft - 226,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <Icon name="arrow-left" />
            </button>
            <span style={{ flex: 1 }} />
            <button
              style={{
                appearance: "none",
                border: "none",
                backgroundColor: designTokens.colors.surface.Background,
                padding: 4,
                borderRadius: 8,
                lineHeight: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                if (pagesRowRef.current) {
                  pagesRowRef.current.scrollTo({
                    left: pagesRowRef.current.scrollLeft + 226,
                    behavior: "smooth",
                  });
                }
              }}
            >
              <Icon name="arrow-right" />
            </button>
          </Row>
        </div>
      )}
    </Column>
  );
}
